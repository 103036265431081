import * as React from "react"
import ContentHeader from "../components/content-header"
import Footer from "../components/footer"

export default (): JSX.Element => {
	return (
		<div className="container">
			<ContentHeader lang="en" />
			<br />
			<br />
			<div
				dangerouslySetInnerHTML={{
					__html: `<h1>Privacy Statement</h1>
				<p>In the following, we would therefore like to inform you about the processing (e.g. collection, storage, transfer) of personal data when using our website. Pursuant to Art. 4 (1) GDPR, the General Data Protection Regulation defines personal data as</p>
				<p style="font-style: italic;">"any information relating to an identified or identifiable natural person (‘data subject’); an identifiable natural person is one who can be identified, directly or indirectly, in particular by reference to an identifier such as a name, an identification number, location data, an online identifier or to one or more factors specific to the physical, physiological, genetic, mental, economic, cultural or social identity of that natural person."</p>
				<br />
				<p style="border-top: 1px solid #004899; font-size: 1px;">.</p>
				<br />

				<h2>Table of Contents</h2>
				<p><strong><a href="#1-0">I Name and address of the controllers</a></strong></p>
				<p><strong><a href="#2-0">II Name and address of the company’s Data Protection Officer</a></strong></p>
				<p><strong><a href="#3-0">III General information on data processing</a></strong></p>
				<ol>
				<li><a data-id="25778" href="/media/306058/dse_besucher.pdf" title="DSE_Besucher.pdf">Scope of processing of personal data</a></li>
				<li><a href="#3-2">Legal basis for the processing of personal data</a></li>
				<li><a href="#3-3">Recipients or categories of recipients to whom the data may be transferred</a></li>
				<li><a href="#3-4">Period of storage, erasure</a></li>
				</ol>
				<p><strong><a href="#4-0">IV Collection and storage of personal data by Flughafen Stuttgart GmbH as well as the type and purpose of their use</a></strong></p>
				<ol>
				<li><a href="#4-1">Visits to the website www.flughafen-stuttgart.de / www.stuttgart-airport.com</a></li>
				<li><a href="#4-2">Use of cookies</a></li>
				<li><a href="#4-3">Use of external services with technically unnecessary cookies</a></li>
				<li><a href="#4-4">Contact forms and e-mail contact</a></li>
				<li><a href="#4-5">Newsletter</a></li>
				<li><a href="#4-6">Social media profiles and comment functions on our website</a></li>
				<li><a href="#4-7">Flight status notification by e-mail</a></li>
				<li><a href="#4-8">Booking options and other functions of our website</a></li>
				<li><a href="#4-9">Subscription to the “Flugblatt” airport magazine</a></li>
				</ol>
				<p><strong><a href="#5-0">V   Rights of the data subject</a></strong></p>
				<ol>
				<li><a href="#5-1">Right to access, pursuant to Art. 15 GDPR</a></li>
				<li><a href="#5-2">Right to rectification, pursuant to Art. 16 GDPR</a></li>
				<li><a href="#5-3">Right to erasure (“Right to be forgotten”), pursuant to Art. 17 GDPR</a></li>
				<li><a href="#5-4">Right to restriction of processing, pursuant to Art. 18 GDPR</a></li>
				<li><a href="#5-5">Right to data portability, pursuant to Art. 20 GDPR</a></li>
				<li><a href="#5-6">Right to object, pursuant to Art. 21 GDPR</a></li>
				<li><a href="#5-7">Right to withdraw your consent for the data protection declaration , pursuant to Art. 7 Para. 3 GDPR</a></li>
				<li><a href="#5-8">Automated individual decision-making, including profiling, pursuant to Art. 22 GDPR</a></li>
				<li><a href="#5-9">Right to lodge a complaint with a supervisory authority, pursuant to Art. 77 GDPR</a></li>
				</ol>
				<p><strong><a href="#6-0">VI Data security</a></strong></p>
				<p><strong><a href="#7-0">VII   Queries</a></strong></p>
				<p><strong><a href="#8-0">VIII Status of this Data Protection Declaration</a></strong><br /><br /></p>
				<p style="border-top: 1px solid #004899; font-size: 1px;">.</p>
				<p> </p>

				<p style="font-size: 1px;">.</p>

				<p style="font-size: 1px;">.</p>
				<h2 id="1-0" style="margin: 0px 0 6px 0;">I   Name and address of the controllers</h2>
				<p style="padding-left: 30px;">Flughafen Stuttgart GmbH<br />Flughafenstraße 32<br />D-70629 Stuttgart<br />Germany</p>
				<p style="padding-left: 30px;">PO Box 23 04 61<br />D-70624 StuttgartGermany</p>
				<p style="padding-left: 30px;">Telefon: +49 711 948-0<br />Telefax: +49 711 948-2241<br />E-Mail: <a href="mailto:info@stuttgart-airport.com">info@stuttgart-airport.com</a></p>
				<p style="padding-left: 30px;"><strong>Legal representatives:</strong><br />Board of Management:</p>
				<p style="padding-left: 30px;">Walter Schoefer (Spokesman) <br/>
				Ulrich Heppe</p>
				<h2 id="2-0" style="margin: 30px 0 6px 0;">II   Name and address of the company’s Data Protection Officer</h2>
				<p style="padding-left: 30px;">You can contact Flughafen Stuttgart GmbH's Data Protection Officer either by using a postal service or e-mail.</p>
				<p style="padding-left: 30px;"><strong>By postal service:</strong><br /><span>Corporate Data Protection Officer<br /></span>Flughafen Stuttgart GmbH<br />Flughafenstraße 32<br />70629 StuttgartGermany</p>
				<p style="padding-left: 30px;"><strong>By e-mail:</strong> <br /><a href="mailto:DSB@stuttgart-airport.com">DSB@stuttgart-airport.com</a></p>
				<h2 id="3-0" style="margin: 30px 0 6px 0;">III   General information on data processing</h2>
				<ol>
				<li id="3-1"><strong>Scope of processing of personal data</strong><br /><br />As a matter of principle, the processing of our users' personal data only takes place<br /><br />
				<ul>
				<li>insofar as this is necessary for the provision of a functional website and for the presentation of the relevant contents</li>
				<li>and/or insofar as we, as the controllers, have obtained the user's consent, pursuant to Art. 6 Para. 1 lit. a GDPR</li>
				<li>and/or insofar as the processing of personal data is permitted by law (Art. 6 Para. 1 lit. b - f GDPR).</li>
				</ul>
				</li><br />
				<li id="3-2"><strong>Legal basis for the processing of personal data</strong><br /><br />Art. 6 Para. 1 lit. a GDPR serves as the legal basis insofar as we obtain the data subject’s consent for the processing of personal data.<br /><br />Art. 6 Para. 1 lit. b GDPR serves as the legal basis for the processing of personal data required for the performance of a contract to which the data subject is a party. This also applies to processing operations that are necessary for performing pre-contractual measures.<br /><br />Art. 6 Para. 1 lit. c GDPR serves as the legal basis insofar as the processing of personal data is required to fulfil a legal obligation to which Flughafen Stuttgart GmbH is subject.<br /><br />Art. 6 Para. 1 lit. f GDPR serves as the legal basis for processing if this is necessary to protect a legitimate interest of Flughafen Stuttgart GmbH or a third party.<br /><br /></li>
				<li id="3-3"><strong>Recipients or categories of recipients to whom the data may be transferred</strong><br /><br />
				<ul>
				<li>Courts, authorities or other state bodies, insofar as a legal obligation exists</li>
				<li>External bodies, insofar as this is necessary to fulfil the purposes stated in each case</li>
				<li>External contractors within the framework of the provisions of Art. 28 GDPR</li>
				<li>Other cooperation partners whose participation is necessary to provide the service</li>
				</ul>
				</li><br />
				<li id="3-4"><strong>Period of storage, erasure</strong><br /><br />Personal data are only stored for as long as this is necessary to achieve the purposes stated in each case or pursuant to the legally stipulated retention periods.</li>
				</ol>
				<h2 id="4-0" style="margin: 30px 0 6px 0;">IV   Collection and storage of personal data by Flughafen Stuttgart GmbH and the type and purpose of its use</h2>
				<ol>
				<li id="4-1"><strong>Visit to the website www.flughafen-stuttgart.de / www.stuttgart-airport.com<br /></strong>
				<p>If you merely use our website www.flughafen-stuttgart.de / www.stuttgart-airport.com for information purposes (only viewing and reading), i.e. if you do not contact us (for example via a contact form) and do not submit any other information to us, we only collect the personal data that are transferred automatically or that your browser - depending on the terminal device and configuration of the client - transfers to our server.</p>
				<p>This information is temporarily stored in a so-called log file. In this case, only data collected by our system are recorded. The following data are collected:</p>
				<ul>
				<li>IP address,</li>
				<li>date and time of access,</li>
				<li>name and URL of the requested page,</li>
				<li>website from which access is made (so-called referrer URL),</li>
				<li>information about the browser type and version used,</li>
				<li>operating system and its interface,</li>
				<li>language and version of the browser software,</li>
				<li>Internet service provider of the user.</li>
				</ul>
				<p>Art. 6 Para. 1 lit. f GDPR constitutes the legal basis for the temporary storage of these data and the log files. The data are stored in log files to ensure the website’s functionality and, in the event of any errors or problems in the network, to carry out an adequate analysis and thus ensure the security of our information technology systems. These data are not evaluated for other purposes. Pursuant to Art. 13 Para. 1 lit. d GDPR, we are herewith informing you as a user that this is in our legitimate interests.</p>
				<p>The log files and thus also the data stored in them are erased after 14 days. The log files are not saved beyond this period.<br /><br /></p>
				</li>
				<li id="4-2"><strong>Use of cookies<br /></strong>
				<p>Our website uses cookies. Cookies are text files that are stored in the Internet browser or by the Internet browser on the user's computer system. If a user calls up a website, cookies can be stored on the user's operating system. The stored cookies contain a characteristic string that enables a unique identification of the browser when the website is visited again.</p>
				<p>We use two types of cookies on our website: technically necessary cookies and technically unnecessary cookies.</p>
				<ul>
				<li><strong>Technically necessary cookies</strong><br />Technically necessary cookies are those used to make our website more user-friendly. Some elements of our website require that the visitor’s browser can be identified even after a page change. Without these cookies, we are unable to provide you with certain functionalities of our website, such as the language settings of our website.<br /><br /></li>
				<li><strong>Technically unnecessary cookies</strong><br />Additionally, we also use cookies on our website that are not technically necessary and enable an analysis of the user's surfing behaviour, such as determining the frequency of page views, or the use of website functions (see also <a href="#4-3a">Section 3</a> of this Data Protection Declaration).<br /><br /></li>
				</ul>
				In the case of cookies, Art. 6 Para. 1 lit. f GDPR constitutes the legal basis for the processing of personal data.<br /><br />The purpose of using technically necessary cookies is to simplify the use of our website for the user. Some functions of our website cannot be offered without the use of cookies. These are functions that require the browser to be recognised even after a page change. Adopting the selected language setting is one example of this. <br /><br />The purpose of using technically unnecessary analysis cookies is to improve the quality of our website and its contents. Through the analysis cookies, we learn how the website is used and can thereby continuously improve the functionalities and contents of our website.<br /><br />Pursuant to Art. 13 para. 1 lit. d GDPR, we are herewith informing you as a user that this is in our legitimate interests.<br /><br />Most browsers automatically accept cookies. The cookies are then stored on the user's terminal device and transferred to us by the user. As a user, you therefore have full control over the use of cookies. You can deactivate or restrict the transfer of cookies by changing the settings in your Internet browser. Cookies that have already been stored can be erased at any time. However, the complete deactivation of cookies may cause you to be unable to use all functions of our website to the full extent. <br /><br /></li>
				<li id="4-3"><strong>Use of external services with technically unnecessary cookies<br /></strong><br />
				<ol>
				<li style="list-style-type: lower-latin;">
				<p id="4-3a"><strong>Google Analytics</strong></p>
				<p>Our website uses Google Analytics, a web analysis service of Google Inc. ("Google"). Google Analytics also uses "cookies", which are text files placed on your computer, to help analyse your website-using behaviour. The information generated by the cookie about your use of this website is usually transferred to a Google server in the US and stored there. The IP address transferred by your browser in the context of Google Analytics is not merged with other Google data. Our website uses Google Analytics with the extension "_anonymizeIp()". This shortens the IP addresses for further processing. Only in exceptional cases, the full IP address is transferred to a Google server in the US and shortened there. On our behalf, Google uses this information to evaluate your use of the website, to compile reports on website activities and to provide us with other services relating to the use of the website and the Internet.</p>
				<p>Art. 6 Para. 1 lit. f GDPR constitutes the legal basis for the processing of personal data, as well as an agreement on processing within the meaning of Art. 28 GDPR. We use Google Analytics to analyse and regularly improve the use of our website. Using theses analyses, we can improve our offer and make it more interesting for you. Pursuant to Art. 13 Para. 1 lit. d GDPR, we are herewith informing you as a user that this is in our legitimate interests.</p>
				<p>As a user, you can at any time erase cookies that have already been set by Google Analytics in your Internet browser. The personal data that Google Analytics collects is erased or made anonymous after 14 months.</p>
				<p>You may refuse the use of cookies by selecting the appropriate settings in your browser; however, please note that if you do this, you may not be able to use the full functionality of our website. You can also prevent Google from collecting the data generated by the cookie and relating to your use of the website (including your IP address) and from Google’s processing of these data by downloading and installing the browser plug-in available at the following link: <br /><a rel="noopener" href="http://tools.google.com/dlpage/gaoptout?hl=de" target="_blank">http://tools.google.com/dlpage/gaoptout?hl=de</a></p>
				<p>Google is certified according to the Privacy Shield Framework and thus offers a safeguard to comply with European data protection law and thus the GDPR.</p>
				<p>More information can be found here: <br /><a rel="noopener" href="https://www.privacyshield.gov/participant?id=a2zt000000001L5AAl&amp;status=active" target="_blank">https://www.privacyshield.gov/participant?id=a2zt000000001L5AAl&amp;status=active</a><br /><a rel="noopener" href="https://www.privacyshield.gov/EU-US-Framework" target="_blank">https://www.privacyshield.gov/EU-US-Framework</a></p>
				<p>Terms of use of Google Analytics: <br /><a rel="noopener" href="http://www.google.com/analytics/terms/de.html" target="_blank">http://www.google.com/analytics/terms/de.html</a></p>
				<p>Overview of data protection: <br /><a rel="noopener" href="http://www.google.com/intl/de/analytics/learn/privacy.html" target="_blank">http://www.google.com/intl/de/analytics/learn/privacy.html</a></p>
				<p>Data protection declaration: <br /><a rel="noopener" href="http://www.google.de/intl/de/policies/privacy" target="_blank">http://www.google.de/intl/de/policies/privacy</a></p>
				<p>You can find a more detailed explanation of Google Analytics at: <br /><a rel="noopener" href="https://www.google.com/intl/de_de/analytics" target="_blank">https://www.google.com/intl/de_de/analytics</a></p>
				</li>
				<li style="list-style-type: lower-latin;"><strong>Google-Maps</strong><br />
				<p>We use the offer of Google Maps on this website. This allows us to display interactive maps directly on the website and enables you to conveniently use the map function.</p>
				<p>By visiting the website, Google receives the information that you have visited the corresponding subpage of our website. In addition, personal data (such as your IP address) are transferred to Google. This occurs regardless of whether you have a Google Account that you are logged in with or whether you do not have a Google Account. If you are logged in to Google, your information will be directly associated with your account. If you do not wish to be associated with your profile on Google, you must log out prior to visiting our website. Google stores your data as usage profiles and uses them for the purposes of advertising, market research and/or demand-oriented design of its website. Such evaluation takes place in particular (even for users who are not logged in) to provide needs-oriented advertising and to inform other users of the social network about your activities on our website. You have a right to object to the creation of these user profiles, whereby you must contact Google to exercise the right to object.</p>
				<p>For more information about the processing of personal data by Google Maps, please see Google's data protection declaration. They will also provide you with further information about your respective rights and setting options to protect your privacy.</p>
				<p>Data protection declaration: <br /><a rel="noopener" href="http://www.google.de/intl/de/policies/privacy" target="_blank">http://www.google.de/intl/de/policies/privacy</a></p>
				<p>Google also processes your personal data in the US and has submitted to the EU-US Privacy Shield: <br /><a rel="noopener" href="https://www.privacyshield.gov/EU-US-Framework" target="_blank">https://www.privacyshield.gov/EU-US-Framework</a>.</p>
				<p>Further information can also be found in the Terms of Use: <br /><a rel="noopener" href="https://www.google.com/intl/de_US/help/terms_maps.html" target="_blank">https://www.google.com/intl/de_US/help/terms_maps.html</a>.</p>
				<a rel="noopener" href="https://www.google.com/intl/de_de/analytics" target="_blank"><br /></a></li>
				<li style="list-style-type: lower-latin;"><strong>affilinet Tracking Cookies</strong><br /><br />Our website uses an affilinet network, operated by affilinet GmbH, Sapporbogen 6-8, 80637 Munich. affilinet uses a cookie on your computer to correctly track sales and/or leads. This cookie is set by the domain partners.webmaster-plan.com or banners.webmasterplan.com. This cookie complies with the applicable privacy policy. The cookies used by affilinet are accepted in the standard settings of your web browser. You can prohibit the storage of these cookies by deactivating the acceptance of the cookies of the corresponding domains in your web browser. affilinet tracking cookies do not store any personal data, but only the ID of the referring partner and the number of the advertising media (banner, text link or similar) clicked by the visitor, which are required for payment processing. When a transaction is completed, the Partner ID is used to assign the commission to be paid to the referring partner. Details can be found in the cookie guidelines of affilinet (https://www.affili.net/de/knowledge-zone/ueber-cookies).<br /><br /><br /></li>
				</ol>
				</li>
				<li id="4-4"><strong>Contact forms and e-mail contact<br /></strong>
				<p>You can contact us by e-mail at <a href="mailto:info@stuttgart-airport.com">info@stuttgart-airport.com</a> In this case, the personal data transferred by e-mail about you are stored.</p>
				<p>There is also a contact form on our website which can be used to contact Flughafen Stuttgart GmbH electronically on various topics.</p>
				<p>When you contact us by e-mail or via a contact form, the data you provide (your e-mail address, your name, your request and when contacting the aircraft noise prevention representative your postcode and residence) are stored by us in order to answer your request appropriately. In addition, a copy of the contact form and thus also your entries, as well as the time of your establishment of contact, is stored in our content management system ("CMS"). The data from the contact form are transferred to our web server in encrypted form.</p>
				<p>Depending on the subject matter on which you contact us by e-mail or using the contact form, your data and your request are transferred to third parties. A transfer to third parties only takes place if this is necessary to clarify and respond to the contact request.<br /><br />A transfer to third parties occurs in particular in the following cases:</p>
				<ul>
				<li>
				<p><strong>For feedback/queries on boarding (e.g. check-in, luggage tracing, boarding support)</strong><br />To clarify the facts of each case, your personal data and your inquiry may be transferred to:</p>
				<p style="font-style: italic;">Stuttgart Airport Ground Handling GmbH (SAG)<br />PO Box 23 02 70<br />D-70622 StuttgartGermany</p>
				<p>and/or to:</p>
				<p style="font-style: italic;">Stuttgart Ground Services GmbH<br />PO Box 23 04 11<br />70624 StuttgartGermany</p>
				</li>
				<li>
				<p><strong>For feedback/queries on security issues (e.g. security check, passport control):</strong><br />To clarify the facts of the case, your personal data and your inquiry may be transferred to:</p>
				<p style="font-style: italic;">Bundespolizeidirektion Stuttgart<br />Wolfgang-Brumme-Allee 52<br />D-71034 BoeblingenGermany</p>
				<p>and/or to:</p>
				<p style="font-style: italic;">Bundespolizeiinspektion am Flughafen Stuttgart<br />D-70629 StuttgartGermany</p>
				<p>and/or to:</p>
				<p style="font-style: italic;">Polizeipräsidium Reutlingen<br />Kaiserstraße 99<br />D-72764 ReutlingenGermany</p>
				<p>and/or to:</p>
				<p style="font-style: italic;">Polizeirevier Flughafen Stuttgart<br />Flughafenstraße 36<br />D-70629 StuttgartGermany</p>
				<p>and/or to:</p>
				<p style="font-style: italic;">Zollamt Flughafen Stuttgart<br />Flughafen Stuttgart<br />D-70629 StuttgartGermany</p>
				</li>
				<li>
				<p><strong>For feedback/queries on check-in and boarding with Lufthansa, Austrian Airlines and Swiss-Air, as well as queries about the Lufthansa Lounge:</strong><br />To clarify the facts of the case, your personal data and your inquiry may be transferred to:</p>
				<p style="font-style: italic;">Deutsche Lufthansa AG<br />Stationsleitung am Flughafen Stuttgart<br />D-70629 StuttgartGermany</p>
				</li>
				<li>
				<p><strong>For feedback/queries on the Elli-Beinhorn-Lounge:</strong><br />To clarify the facts of the case, your personal data and your inquiry may be transferred to:</p>
				<p style="font-style: italic;">ChillOutLounges GmbH<br />PO Box 420 131<br />D-30662 HannoverGermany</p>
				</li>
				<li>
				<p><strong>For feedback/queries on shops or catering services on the terrain of the airport:</strong><br />To clarify the facts of the case, your personal data and your inquiry may be transferred to to shops or catering services on the campus of Stuttgart Airport.</p>
				</li>
				<li>
				<p><strong>For queries on noise abatement:</strong><br />Your personal data and your request are forwarded directly to the Noise Abatement Officer of the Stuttgart Regional Board. By contacting the Noise Abatement Officer of the Stuttgart Regional Board, furthermore the mention of the postcode and the residence is necesary. This enables the Noise Abatement Officer of the Stuttgart Regional Board to follow up your request and answer your questions appropriately.</p>
				<p style="font-style: italic;">Lärmschutzbeauftragter für den Flughafen Stuttgart<br />Regierungspräsidium Stuttgart<br />Ruppmannstraße 21<br />D-70565 StuttgartGermany</p>
				</li>
				</ul>
				<p>Art. 6 Para. 1 lit. f GDPR and Art. 6 Para. 1 lit. a GDPR constitute the legal bases for data processing if you contact us by e-mail or via the contact form.</p>
				<p>Regardless of whether you contact us by e-mail or using the contact form, your data are used exclusively to process and answer your enquiry appropriately. This also applies to any transfer of your data to third parties.</p>
				<p>Personal data are only stored for as long as this is necessary to achieve the above-mentioned purpose or pursuant to the legally stipulated retention periods.</p>
				<p>This is the case with the personal data that you send us as part of an enquiry by e-mail or contact form when the conversation with the user has ended, i.e. when the relevant facts have been finally clarified.</p>
				<p>The data are only processed to be able to answer your contact request appropriately and conclusively. Pursuant to Art. 13 Para. 1 lit. d GDPR, we are herewith informing you as a user that this is in our legitimate interests.</p>
				</li>
				<li id="4-5"><strong><strong>Newsletter<br /><br /></strong></strong>Flughafen Stuttgart GmbH offers special user groups (travel agency partners, business partners/corporates and members of the press) the option of subscribing for a target group-oriented newsletter. With these newsletters, we regularly send out target group-relevant information/invitations about Flughafen Stuttgart GmbH by e-mail.
				<p>Art. 6 Para. 1 lit. a GDPR constitutes the legal basis for the processing of the data after the user registers for the newsletter. The collection of your personal data serves for verification and correct allocation to the target group-oriented newsletter and to ensure that the newsletter can be delivered. Your data are used exclusively for sending the newsletter.</p>
				<p>Your personal data are only stored for as long as necessary to achieve the above-mentioned purpose or pursuant to the legally stipulated retention periods. We store your data for as long as the subscription to your newsletter is active. As a user, you can cancel your subscription to the respective newsletter at any time by withdrawing your consent and without needing to give any reasons.</p>
				<p>To do this, you can send an e-mail to <a href="mailto:Betroffenenrechte@stuttgart-airport.com">Betroffenenrechte@stuttgart-airport.com</a> or click the “Unsubscribe” button in the newsletter.</p>
				<ol>
				<li style="list-style-type: lower-latin;">
				<p><strong>Newsletter for travel agency partners, business partners/corporates</strong><br /><br />If you belong to the travel agency partners, business partners user group and are interested in subscribing to the “Travel agency partners, business partners/corporates” newsletter, please contact <a href="mailto:Marketing@stuttgart-airport.com">Marketing@stuttgart-airport.com</a>. To consider your registration, we need information about you (e-mail address, name) and the organisation/company to which you belong. <a href="#4-4">Section 4</a> of this Data Protection Declaration applies to contacting us by e-mail.</p>
				<p>The “Newsletter2Go” newsletter software is used to send the newsletter to travel agency partners, business partners/corporates. Your data are transferred to Newsletter2Go GmbH. Newsletter2Go is prohibited from selling your data or using it for any purpose other than sending the newsletter. Newsletter2Go is a certified provider in Germany, selected according to the requirements of the GDPR.</p>
				<p>Further information can be found here: <br /><a rel="noopener" href="https://www.newsletter2go.de/informationen-newsletter-empfaenger" target="_blank">https://www.newsletter2go.de/informationen-newsletter-empfaenger</a></p>
				<p>Art. 6 Para. 1 lit. f GDPR constitutes the legal basis for the transfer to Newsletter2Go. The dispatch service provider is used to ensure the smooth technical dispatch of the newsletters and to guarantee the IT integrity of Flughafen Stuttgart GmbH's servers. Pursuant to Art. 13 para. 1 lit. d GDPR, we are herewith informing you as a user that this is in our legitimate interests.</p>
				</li>
				<li style="list-style-type: lower-latin;">
				<p><strong>Newsletter for members of the press (“Press Newsletter”)</strong><br /><br />If you are a member of the Press user group and are interested in subscribing to the “Presserundschreiben” newsletter, please contact <a href="mailto:Presse@stuttgart-airport.com">Presse@stuttgart-airport.com</a>. To consider your registration, we need information about you (e-mail address, name) and the organisation/company to which you belong. <a href="#4-4">Section 4</a> of this Data Protection Declaration applies to contacting us by e-mail.</p>
				</li>
				</ol>
				</li>
				<li id="4-6"><strong>Social media profiles and comment functions on our website</strong><br />
				<ol>
				<li style="list-style-type: lower-latin;">
				<p><strong>Social media profiles</strong><br />Flughafen Stuttgart GmbH maintains profiles on various social networks. You can find links to these profiles on our website. Flughafen Stuttgart GmbH maintains the following profiles:</p>
				<ul>
				<li><a rel="noopener" href="https://www.facebook.com/FlughafenStuttgart" target="_blank">​https://www.facebook.com/FlughafenStuttgart</a></li>
				<li><a rel="noopener" href="https://www.instagram.com/Stuttgartairport" target="_blank">https://www.instagram.com/Stuttgartairport</a></li>
				<li><a rel="noopener" href="https://www.snapchat.com/add/str_flughafen" target="_blank">https://www.snapchat.com/add/str_flughafen</a></li>
				<li><a rel="noopener" href="https://www.youtube.com/user/StuttgartAirport" target="_blank">https://www.youtube.com/user/StuttgartAirport</a></li>
				<li><a rel="noopener" href="https://www.xing.com/companies/flughafenstuttgartgmbh" target="_blank">https://www.xing.com/companies/flughafenstuttgartgmbh</a></li>
				</ul>
				<p>We look forward to you visiting one of our profiles in the above-mentioned networks. In this case, the terms and conditions and data protection regulations of the respective operators apply.<br /><br />All references to social media profiles on our website are integrated by means of a link. Personal data are therefore not transferred on to third parties without your active involvement.<br /><br />A transfer only takes place if you initiate it yourself by clicking on the link. In this case, the data protection regulations of the respective social network apply. As a user, your respective rights and setting options to protect your privacy can be found in the providers' data protection information.</p>
				<ul>
				<li><a rel="noopener" href="https://www.facebook.com/policy.php" target="_blank">https://www.facebook.com/policy.php<br /></a></li>
				<li><a rel="noopener" href="https://help.instagram.com/155833707900388" target="_blank">https://help.instagram.com/155833707900388<br /></a></li>
				<li><a rel="noopener" href="https://www.snap.com/en-US/privacy/privacy-policy" target="_blank">https://www.snap.com/en-US/privacy/privacy-policy<br /></a></li>
				<li><a rel="noopener" href="https://www.youtube.com/static?template=privacy_guidelines" target="_blank">https://www.youtube.com/static?template=privacy_guidelines<br /></a></li>
				<li><a rel="noopener" href="https://privacy.xing.com/de/datenschutzerklaerung" target="_blank">https://privacy.xing.com/de/datenschutzerklaerung</a></li>
				</ul>
				</li>
				<li style="list-style-type: lower-latin;">
				<p><strong>Activation of DISQUS in our blog</strong></p>
				<p>In our blog, various authors describe their personal views on a topic and report from a subjective perspective on topics relating to Stuttgart Airport. Readers can comment on these contributions. We use the DISQUS comment service for this, offered by DISQUS Inc, 301 Howard Street, Suite 300, San Francisco, CA 94105, US (“DISQUS”).</p>
				<p>This comment function is disabled by default. To be able to use the DIQUS comment function, the user must first activate it. This is done by setting an appropriate cookie. If you do not activate the DISQUS comment function, no personal data are transferred to DISQUS.</p>
				<p>You can activate DISQUS on our website by clicking on the “Activate DISQUS (comments)” button. Then you can log in to DIQUS using an existing DISQUS account or using an existing social media account (for example, Facebook) and write comments. You can also use the DISQUS function as a guest, without creating an account with DISQUS and without creating a social media account. When you activate the comment function DISQUS and/or write comments, personal data, such as:</p>
				<ul>
				<li>your IP address,</li>
				<li>time of the comment,</li>
				<li>content of the comment (e.g. also name, e-mail address of the comment creator)</li>
				</ul>
				<p>are transferred to DISQUS and thus to servers in third countries. DISQUS and its function is embedded on our website only. The use of the comment function therefore takes place according to the conditions of DISQUS and exclusively in the relationship between you, as user, and DISQUS itself. Please, therefore, read the data protection information (<a rel="noopener" href="https://help.disqus.com/terms-and-policies/diqus-privacy-policy" target="_blank">https://help.disqus.com/terms-and-policies/diqus-privacy-policy</a>) and the DISQUS terms of use (<a rel="noopener" href="https://help.disqus.com/terms-and-policies/terms-of-service" target="_blank">https://help.disqus.com/terms-and-policies/terms-of-service</a>). To erase data, only DISQUS can be contacted.<br />As a user and as far as you activate the comment function of DISQUS, the use of DISQUS and the associated transfer of personal data to DISQUS is legally based on Art. 6 Para. 1 lit. f GDPR.</p>
				<p>Personal data is processed for the purpose of an efficient and user-friendly design of the comment management.</p>
				<p>Pursuant to Art. 13 para. 1 lit. d GDPR, we are herewith informing you as a user that this is in our legitimate interests.</p>
				<p>DISQUS is certified according to the Privacy Shield Framework and thus offers a safeguard to comply with European data protection law and thus the GDPR.</p>
				<p>The link to the privacy policy can be found here: <a rel="noopener" href="https://www.privacyshield.gov/participant?id=a2zt0000000TRkEAAW&amp;status=Active" target="_blank">https://www.privacyshield.gov/participant?id=a2zt0000000TRkEAAW&amp;status=Active</a></p>
				<p>If you do not wish your personal data to be transferred to DISQUS, we recommend that you do not activate the comment function.</p>
				</li>
				</ol>
				</li>
				<li id="4-7"><strong><strong>Flight status notification by e-mail<br /></strong></strong>
				<p>As a user of our website, you have the option of being notified by e-mail about status updates of flights that you can select yourself.</p>
				<p>As a user, you can subscribe to the flight status (for example, status: en-route; status: landed; status: boarding) and flight status changes for a specific flight by e-mail. For this purpose, we collect your e-mail address in the registration form for notification. This data is only collected to inform you of any changes in the flight status of the desired flight(s).</p>
				<p>Registration for flight status notification occurs via the so-called Double-Opt-In procedure. This means that after registration you will receive an e-mail asking you to confirm your registration for flight status notification by clicking a link. Only if you make this confirmation will you receive the desired flight status notifications and flight status changes by e-mail. In the course of the Double-Opt-In procedure, the following data are stored:</p>
				<ul>
				<li>e-mail,</li>
				<li>IP address in the server log,</li>
				<li>time of confirmation.</li>
				</ul>
				Art. 6 Para. 1 lit. a GDPR constitutes the legal basis for this data processing. <br /><br />Personal data are only stored for as long as this is necessary to achieve the above-mentioned purpose or pursuant to the legally stipulated retention periods. Your e-mail address is routinely erased after a period of 4 days, but no later than 7 days, after the end of the respective flight.<br /><br /></li>
				<li><strong>Booking options and other functions of our website</strong><br />
				<ol>
				<li style="list-style-type: lower-latin;">
				<p><strong>bookingg VIP services</strong><br /><br />On our website, you have the possibility to book VIP services around your flight. These are special services (e.g. shuttle service).</p>
				<p>You will find a booking form on our website for this purpose. Depending on which VIP package you book, we request various information required to book the package (e.g. your name, number of adults and children travelling with you, flight details). As the performance of the ordered service takes place at an international commercial airport and is therefore subject to legal security requirements, we pass on your data to third parties within the framework of the execution of the contract, insofar as this is necessary. In this sense, third parties are the authorities involved (cf. <a href="#4-0">Section 4</a>) as well as the internal departments required for the execution of the contract (e.g. terminal supervision, operations officer on duty) and external cooperation partners (e.g. the booked airline, the handling partner responsible for this airline, the security service provider used for personnel and goods control). Art. 6 Para. 1 lit. b GDPR constitutes the legal basis for this data processing. The data are processed exclusively for the purpose of executing the contract. <br />Personal data are only stored for as long as this is necessary to achieve the above-mentioned purpose or pursuant to the legally stipulated retention periods.</p>
				</li>
				<li style="list-style-type: lower-latin;">
				<p><strong>Booking airport tours</strong><br /><br />You can book airport tours on our website. We provide you with a platform for your booking enquiry/booking for this purpose. Within the framework of the booking enquiry/booking, we collect the data necessary for carrying out the tour (number of participants, type of group, address of the contact person/booking organisation or the booking company, billing address, telephone number, e-mail address). After booking, you receive an acknowledgement of receipt of the reservation request and a confirmation e-mail if the booking was successful.</p>
				<p>Art. 6 Para. 1 lit. b GDPR constitutes the legal basis for this data processing. The data to be provided are required for the execution and processing of the airport tour and booking. The transfer of data to us is encrypted as part of the SSL encryption of our website.</p>
				<p>Personal data are only stored for as long as this is necessary to achieve the above-mentioned purpose or pursuant to the legally stipulated retention periods.</p>
				</li>
				<li style="list-style-type: lower-latin;">
				<p><strong>Application portal</strong><br /><br />Online, you have the opportunity to apply for a job with us using the application portal “d.vinci” of d.vinci HR-Systems GmbH, Nagelsweg 37 - 39, D-20097 Hamburg, Germany; this is integrated on our homepage.</p>
				<p>The personal data which you enter are only stored by d.vinci HR-Systems GmbH. Art. 6 Para. 1 lit. b GDPR constitutes the legal basis for this data processing. We have carefully selected d.vinci HR-Systems GmbH and have made contractual regulations in accordance with the requirements of the GDPR (Art. 28 GDPR).</p>
				</li>
				</ol>
				</li>
				<li id="4-9"><strong>Subscription to the “Flugblatt” airport magazine<br /></strong>
				<p>You can register for a free subscription to our “Flugblatt” airport magazine on our website. If you would like to receive the airport magazine, please send an e-mail with your name and full address to <a href="mailto:publikationen@stuttgart-airport.com">publikationen@stuttgart-airport.com</a>.</p>
				<p>If you register to receive the airport magazine, your personal data are processed exclusively for the purpose of sending the magazine. Your personal data are required to send you our airport magazine and thus to provide the service for your requested subscription.</p>
				<p>Art. 6 Para. 1 lit. a GDPR constitutes the legal basis for the processing of your personal data. Your personal data are only stored for as long as it is necessary to provide the subscription. We store your data as long as your subscription to the magazine remains active. Your personal data are forwarded to the shipping service for the purpose of sending you the magazine. Your personal data are not transferred to other third parties.</p>
				<p>You can cancel your subscription to the airport magazine at any time and without giving reasons by withdrawing your consent.</p>
				<p>You can send an e-mail to Betroffenenrechte@stuttgart-airport.com at any time.</p>
				<p><a href="#4-4">Section IV, Paragraph 4</a> of this Data Protection Declaration applies to contacting us by e-mail.</p>
				</li>
				</ol>
				<h2 id="5-0" style="margin: 30px 0 6px 0;">V Rights of the data subject</h2>
				<p>Within the meaning of the GDPR, you are a data subject if we process your personal data. Therefore, you have rights vis-à-vis Flughafen Stuttgart GmbH as the controller. If you wish to exercise such a right, please contact:</p>
				<p>Flughafen Stuttgart GmbH<br />Flughafenstraße 32<br />70629 Stuttgart<br />GermanyE-Mail: <a href="mailto:Betroffenenrechte@stuttgart-airport.com">Betroffenenrechte@stuttgart-airport.com</a></p>
				<p>You are entitled to the following rights:</p>
				<ol>
				<li id="5-1">
				<p><strong>Right to access, pursuant to Art. 15 GDPR</strong><br /><br />Pursuant to Art. 15 GDPR, you have the right to access information on your personal data processed by us. In particular, you may access information on the purposes for the processing, the category of personal data, the categories of recipients to whom your data have been or will be transferred, the planned storage period, the existence of a right to rectification, erasure, restriction of processing or objection, the existence of a right of lodging a complaint, the origin of your data, if these have not been collected by us, and the existence of automated decision-making including profiling and, if applicable, meaningful information on their details.</p>
				<p>You also are entitled to the right to access information as to whether your personal data is being transferred to a third country or to an international organisation. In this context and pursuant to Art. 46 GDPR, you may request to be informed of the appropriate safeguards in connection with the transfer.</p>
				</li>
				<li id="5-2">
				<p><strong>Right to rectification, pursuant to Art. 16 GDPR</strong><br /><br />Vis-à-vis us as data controller and in accordance with Article 16 of the GDPR, you are entitled to the right to rectify and/or complete your personal data if the personal data relating to you and being processed is incorrect or incomplete. We, as controllers, must make this rectification without delay.</p>
				</li>
				<li id="5-3">
				<p><strong>Right to erasure (“Right to be forgotten”), pursuant to Art. 17 GDPR</strong><br /><br />Pursuant to Art. 17 Para. 1 GDPR, you are entitled to the right to request the erasure of your personal data stored with us, unless the processing is necessary to exercise the right to freedom of expression and information, to fulfil a legal obligation, for reasons of public interest or to assert, exercise or defend legal claims (cf. Art. 17 Para. 3 GDPR).</p>
				<p>If we, as controller, have made the personal data relating to you public and we are obliged to erase this personal data pursuant to Art. 17 Para. 1 GDPR, we will take appropriate measures, including technical measures (taking into account the available technology and the costs for implementation) to inform other data processors who process the personal data that you, as data subject, have requested the erasure of all links to these personal data or of copies or replications of these personal data.</p>
				</li>
				<li id="5-4">
				<p><strong>Right to restriction of processing, pursuant to Art. 18 GDPR</strong><br /><br />Pursuant to Article 18 GDPR, you are entitled to the right to demand the restriction of the processing of your personal data if you dispute the accuracy of the data, the processing is unlawful but you reject their erasure and we no longer need the data, but you need them to assert, exercise or defend any legal claims or you have filed an objection against the processing pursuant to Article 21 GDPR.</p>
				</li>
				<li id="5-5">
				<p><strong>Right to data portability, pursuant to Art. 20 GDPR</strong><br /><br />Pursuant to Art. 20 GDPR, you are entitled to the right to receive the personal data you have provided us with in a structured, common and machine-readable format or to request the transfer to another controller.</p>
				</li>
				<li id="5-6">
				<p><strong>Right to object, pursuant to Art. 21 GDPR</strong><br /><br />On the basis of Article 6(1)(e) or (f) of the GDPR, you are entitled to the right to object at any time to the processing of personal data concerning you for reasons arising from your particular situation.</p>
				<p>As controllers, we then no longer process the personal data relating to you unless we can prove that there are compelling reasons worthy of protection for the processing which outweigh your interests, rights and freedoms, or the processing serves to assert, exercise or defend any legal claims. If the personal data relating to you are processed for direct marketing purposes, you have the right to object to the processing at any time. If you object to the processing for direct marketing purposes, the personal data concerning you is no longer processed for these purposes.</p>
				</li>
				<li id="5-7">
				<p><strong>Right to withdraw your consent for the data protection declaration, pursuant to Art. 7 Para. 3 GDPR</strong><br /><br />You are entitled to the right to withdraw your consent for this Data Protection Declaration at any time. The withdrawal of consent shall not affect the legality of the processing carried out on the basis of the consent until withdrawal.</p>
				</li>
				<li id="5-8">
				<p><strong>Automated individual decision-making, including profiling, pursuant to Art. 22 GDPR</strong><br /><br />You are entitled to the right not to be subject to a decision based exclusively on automated processing - including profiling - that has any legal effect against you or significantly impairs you in any similar manner.</p>
				</li>
				<li id="5-9">
				<p><strong>Right to lodge a complaint with a supervisory authority, pursuant to Art. 77 GDPR</strong><br /><br />You are entitled to the right to lodge a complaint with a supervisory authority pursuant to Art. 77 GDPR. As a rule, you can contact the supervisory authority of your place of residence, workplace or our company headquarters.</p>
				<p>The supervisory authority responsible for Flughafen Stuttgart GmbH is:</p>
				<p>Der Landesbeauftragte für den Datenschutz und die Informationssicherheit<br />PO Box 10 29 32<br />70025 StuttgartGermany</p>
				<p>Telefon: 0711/615541-0<br />Fax: 0711/615541-15<br />E-Mail: <a href="mailto:Poststelle@lfdi.bwl.de">Poststelle@lfdi.bwl.de</a></p>
				</li>
				</ol>
				<h2 id="6-0" style="margin: 30px 0 6px 0;">VI Data security</h2>
				<p>We use SSL (Secure Socket Layer) encryption with 256-bit encryption on our website. You can recognise the encryption of our website and its individual areas by the lock or key symbol in the lower status bar of your browser.</p>
				<p>Within the meaning of Art. 32 GDPR, we principally use suitable technical and organisational measures to protect your data against accidental or intentional manipulation, partial or complete loss, destruction or against unauthorised access by third parties. Our security measures are constantly developed and improved in accordance with technical progress and technical development.</p>
				<h2 id="7-0" style="margin: 30px 0 6px 0;">VII  Queries</h2>
				<p>If you have any questions regarding the processing of your personal data by us or your rights as a data subject, you can contact our Data Protection Officer at any time. Also, if you have any suggestions or other information on the subject of data protection, you can contact our Data Protection Officer at any time. You will find the contact details in II. of this Data Protection Declaration.</p>
				<h2 id="8-0" style="margin: 30px 0 6px 0;">VIII   Status of this Data Protection Declaration</h2>
				<p>This Data Protection Declaration is currently valid and dated 25 May 2018.</p>
				<p> </p>`,
				}}
			/>

			<Footer suffix="en" />
		</div>
	)
}
